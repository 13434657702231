/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        // $('.megamenu-link').mouseover(function(){
        //     if ($(this).hasClass('megamenu-link-selected')) return;
        //     $('.megamenu-link-selected').removeClass('megamenu-link-selected');
        //     $(this).addClass('megamenu-link-selected');
        // });
        // $('table[style]').removeAttr('style');

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#sideNav").toggleClass('sideNav-open');
          $(".sideNavBody").toggleClass('sideNavBody-push');
          removeClass = false;
        });

        $(".sideNav").click(function() {
          removeClass = false;
        });

        document.addEventListener('touchstart', function(e) {
          if (removeClass && !$(e.target).hasClass('sideNav') && $('.sideNav').has($(e.target)).length === 0) {
             $(".hamburger").removeClass('is-active');
             $("#sideNav").removeClass('sideNav-open');
             $(".sideNavBody").removeClass('sideNavBody-push');
          }
          removeClass = true;
        }, false);
        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });
        $('.dropdown-toggle').click(function(){
          $(this).toggleClass('dropdown-toggle-open');
        });
        $('#myTab a').click(function (e) {
          e.preventDefault();
          $(this).tab('show');
        });
      }
    },
    // single-products content
    'single_products': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.cad-drawings').click(function(){
          $('body').delay(100).queue(function (next) {
              $('.lg-toolbar').append('<button class="lg-print print-primary" onclick="window.print();"><i class="fa fa-print"></i></button>');
              next();
          });
        });
      },
      finalize: function() {
        $('.gallery-repeater-ul').lightGallery({});
        $('.gallery-repeater-row').lightGallery({});
        $('.schematics-contain').lightGallery({});
        $('.feat-img-contain').click(function(){
          $(this).toggleClass('feat-img-contain-active');
        });
        $('.additional-information-cad').click(function(){
          document.body.scrollTop = document.documentElement.scrollTop = 0;
        });
        //print screen
        $('.print-primary').click(function(){
          $('.show').css('background-color', '#fff');
          // $('.lg-img-wrap').css('background-color', '#fff');
          $('header').hide().show(100);
          $('.product-container').hide().show(100);
          $('.second-cta').hide().show(100);
          $('footer').hide().show(100);

          window.print();
          $('.show').css('background-color', 'rgba(0,0,0,0.4)');
          // $('.lg-img-wrap').css('background-color', 'rgba(0,0,0,0.9)');
        });
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        $( ".product-info" ).hover(function() {
          $(".product-img-desktop").css('opacity', '0').hide();
          $(this).addClass('product-info-active').siblings('.product-info-active').removeClass('product-info-active');
          $($(this).data("target")).show().animate({
            opacity: 1
          }, 100 );
        });
      }
    },
    'infoDisplayTabs': {
      finalize: function() {
        // Select all links with hashes
        $('.additional-information-scroll')
          // Remove links that don't actually link to anything
          .not('[href="#"]')
          .not('[href="#0"]')
          .click(function(event) {
            // On-page links
            if(location.pathname.replace(/^\//, '') === this.pathname.replace(/^\//, '') && location.hostname === this.hostname) {
              // Figure out element to scroll to
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
              // Does a scroll target exist?
              if (target.length) {
                // Only prevent default if animation is actually gonna happen
                event.preventDefault();
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000, function() {
                  // Callback after animation
                  // Must change focus!
                  var $target = $(target);
                  $target.focus();
                  if ($target.is(":focus")) { // Checking if the target was focused
                    return false;
                  } else {
                    $target.attr('tabindex','-1'); // Adding tabindex for elements not focusable
                    $target.focus(); // Set focus again
                  }
                });
              }
            }

          });
        $('.additional-information-anchor').click(function(){
          var anchorli = $(this).attr('data-tabbycat');
          var anchortab = $(anchorli).attr('href');
          $('.nav-link-addlinfo').removeClass('active');
          $('.tab-pane-addlinfo').removeClass('active');
          $(anchorli).addClass('active');
          $(anchortab).addClass('active');
        });
        // console.log('sup dude');
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Configurator test
    'single_configurators':{
      init: function() {
          // debugger;
          // var partnumber_parts = [];
          // JavaScript to be fired on the configurator page
          var partnumber_input_name = null;
          jQuery( function(){ //onReady
              // debugger;
              //function to update the part number field
              var updatePN = function(){
                // debugger;
                console.log(partnumber_input_name);
                var fieldData = jQuery('.gform_body').find(':input:not(.small)').serializeArray();
                var partnumber = "";
                jQuery.each(fieldData, function(idx, fieldDatum){
                  if( fieldDatum.name !== partnumber_input_name ){
                    if( idx > 0 ){
                      partnumber += '-';
                    }
                    partnumber += fieldDatum.value;
                  }
                });
                jQuery("input[name='" + partnumber_input_name + "'").val(partnumber);
              };

              //Get the partnumber input
              partnumber_input_name = jQuery('.gform_body').find('.p_partnumber').find('input').attr('name');

              jQuery('.gform_body').on( 'keyup change', ':input', function(){
                console.log(this);
                console.log(partnumber_input_name);
                // debugger;
                var current_input_name = jQuery(this).attr('name');
                if( current_input_name !== partnumber_input_name ){
                  updatePN();
                }
              });

              jQuery(document).on('click', '.delete_list_item', function(){
                //not an input so we don't have to check for part number
                console.log("Deleted Item");
                updatePN();
              });

              updatePN();

          });

      },
      finalize: function() {
        // JavaScript to be fired on the configurator page, after the init JS

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
